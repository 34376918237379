import React from "react";
import styled from 'styled-components';
import Carousel from '../components/carousel';
import Intro from '../chunks/intro';
import Card from "../components/card";
import { graphql, StaticQuery } from "gatsby";
import breakpoint from 'styled-components-breakpoint';
import Button from '../components/button';
import _ from 'lodash';
import Link from '../components/betterLink';
import SEO from "../components/seo";
import HtmlReactParser from 'html-react-parser';

const CarouselWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 10px 20px 10px;
  ${breakpoint('tablet')`
    padding: 0 0 60px 0;
  `}
`;

const IntroButton = styled(Button)`
  display: block;
  ${breakpoint(`tablet`)`
    margin: 0 auto 60px auto;
  `}
  margin: 30px auto 120px auto;
`;

const Wrapper = styled.div`
  ${breakpoint('tablet')`
    padding: 60px;
  `}
`;

const Content = ({data})=>{
  const { title, nameSplitPosition, indexCarousel, indexIntroImage } = data.setting.edges[0].node.frontmatter;
  const companyDescription = data.companyDescription.edges[0].node.html;
  const carouselFluids = indexCarousel.map(node=>node.childImageSharp.fluid);
  const services = data.services.edges.sort((lhs,rhs)=>lhs.node.frontmatter.order - rhs.node.frontmatter.order);
  return(
    <Wrapper>
      <SEO title="首頁"/>
      <CarouselWrapper>
        <Carousel fluids={carouselFluids} />
      </CarouselWrapper>
      <Intro 
        title={title}
        nameSplitPosition={nameSplitPosition}
        description={companyDescription}
        descriptionLimit={100}
        fluid={indexIntroImage.childImageSharp.fluid}
      />
      <Link to="/about">
        <IntroButton text="關於我們"/>
      </Link>
      {
        services.map(({node},index)=>{
          const { title, nameSplitPosition, subtitle, thumbnail, templateKey } = node.frontmatter;
          const html = node.html.replace("<br>",'');
          return(
            <Card 
              key={index}
              title={title}
              nameSplitPosition={nameSplitPosition}
              subtitle={subtitle}
              description={HtmlReactParser(html)}
              descriptionLimit={57}
              fluid={thumbnail.childImageSharp.fluid}
              link={`/${templateKey}/${_.kebabCase(title)}/`}
            />
          )
        })
      }
    </Wrapper>
  );
};

const IndexPage = () => (
  <StaticQuery 
    query={query}
    render={data=><Content data={data}/>}
  />
);

export default IndexPage;


const query = graphql`
  query {
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            title
            nameSplitPosition
            indexCarousel{
              childImageSharp{
                fluid(maxWidth: 2048){
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            indexIntroImage{
              childImageSharp{
                fluid(maxWidth: 400){
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    companyDescription: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "companyDescription" } }
    }){
      edges{
        node{
          html
        }
      }
    }
    services: allMarkdownRemark( filter: { 
      frontmatter:{ templateKey:{ eq: "service" } }
    }){
      edges{
        node{
          fields{
            slug
          }
          html
          frontmatter{
            templateKey
            title
            nameSplitPosition
            order
            subtitle
            keywords
            thumbnail{
              childImageSharp{
                fluid(maxWidth: 300){
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;