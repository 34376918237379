import React from 'react';
import styled from 'styled-components';
import NukaCarousel from 'nuka-carousel';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Img from 'gatsby-image';

const SlideButton = styled.div`
    cursor: pointer;
    font-size: 70px;
    color: ${({theme})=>theme && theme.color ? theme.color.text.primary: "#B2B1B1"};
    -webkit-tap-highlight-color: transparent;
`;

const Image = styled(Img)`
  cursor: grab;
  width: calc( 500px * ${({ratio})=>ratio} );
  height: 500px;
`;

const ImageWrapper=styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus{
        outline: none;
    }
`;

const Carousel = ({fluids})=>{

    return(
        <NukaCarousel
            renderCenterLeftControls={({previousSlide})=>(
                <SlideButton onClick={previousSlide}><IoIosArrowBack /></SlideButton>
            )}
            renderCenterRightControls={({nextSlide})=>(
                <SlideButton onClick={nextSlide}><IoIosArrowForward /></SlideButton>
            )}
            autoplay 
            autoplayInterval={8 * 1000} 
            pauseOnHover 
            swiping 
            cellSpacing={5} 
            wrapAround
        >
            {fluids.map((fluid,index)=>(
                <ImageWrapper key={index}>
                    <Image fluid={fluid} ratio={fluid.aspectRatio} />
                </ImageWrapper>
            ))}
        </NukaCarousel>
    )
}

export const PreviewCarousel = ({images})=>{

    return(
        <NukaCarousel
            renderCenterLeftControls={({previousSlide})=>(
                <SlideButton onClick={previousSlide}><IoIosArrowBack /></SlideButton>
            )}
            renderCenterRightControls={({nextSlide})=>(
                <SlideButton onClick={nextSlide}><IoIosArrowForward /></SlideButton>
            )}
            autoplay 
            autoplayInterval={8 * 1000} 
            pauseOnHover 
            swiping 
            cellSpacing={5} 
            wrapAround
        >
            {images.map((src,index)=>(
                <img height="500px" width="auto" style={{objectFit: "scale-down"}} src={src} key={index} alt="carousel"/>
            ))}
        </NukaCarousel>
    )
}

export default Carousel;

