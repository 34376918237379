import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import breakpoint from 'styled-components-breakpoint';
import HtmlReactParser from 'html-react-parser';
import reactToString from '../helper/reactToString';

const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 250px;
    ${breakpoint('tablet')`
        width: 300px;
        height: 300px;
    `}
    text-align: center;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${breakpoint('tablet')`
        flex-direction: row;
    `}
`;

const Line = styled.div`
    height: 1px;
    width: 240px;
    border-bottom: 2px solid black;
    border-right: none; 
    ${breakpoint('tablet')`
        height: 240px;
        width: 1px;
        border-bottom: none; 
        border-right: 2px solid black; 
    `}
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    flex: 1;
    font-size: 40px;
`;

const HtmlWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 8px 15px;
    flex: 1;
    font-size: 16px;
`;

const Image = styled(Img)`
    width: 100%;
    height: 100%;
`;

const Intro = ({title, nameSplitPosition, description, fluid, descriptionLimit})=>{
    description = reactToString(HtmlReactParser(description));
    if(descriptionLimit){
        description = 
            description.length > descriptionLimit 
            ? description.slice(0, descriptionLimit)+"..." 
            : description;
    }
    const main = title.slice(0,nameSplitPosition);
    const sub = title.slice(nameSplitPosition);
    return(
        <Wrapper>
            <Item>
                <Title>
                    {main&&<div>{main}</div>}
                    {sub&&<div>{sub}</div>}
                </Title>
            </Item>
            <Line />
            <Item>
                <HtmlWrapper>{description}</HtmlWrapper>
            </Item>
            <Item>
                <Image fluid={fluid} />
            </Item>
        </Wrapper>
    );
};

export default Intro;